.body {
  background-color: rgba(233, 231, 231, 0.948);
}


.text{
  height: 100px !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.h1Tags{
  white-space: nowrap;
}

.pHeight{
  height: 150px;
  overflow: hidden;
}
.pHeightTheme{
  height: 60px;
  overflow: hidden;
}

/*Header control*/
@media (min-width: 1300px ) {
  .hederCon{
    display: none;
  }
}
@media (max-width: 1300px ) {
  .headerControl{
    display: none;
  }
}

.headerBack {
  background: url('../backgrounds/backHeader.jpg');

}

.marginThero {
  margin: 0px;
  max-width: 100% !important;
}

.paddingThero {
  padding: 0px !important;
  max-width: 100% !important;
}

.HeaderNav {
  background-color: rgb(211, 209, 209);
  height: 50px !important;
}

.headerText {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.logo1 {
  color: rgb(205, 182, 8);
  font-size: 24px;
  float: left;
  display: flexbox;
  align-content: center !important;
}

.logo2 {
  color: rgb(5, 5, 5);
  font-size: 24px;
  float: left;
}

.logo3 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  float: left;
}

.marginThirty {
  margin-top: 6px;
  max-width: 60%;
  float: left;
}

.padding1 {
  padding-left: 5px;
}

.text-color {
  color: #f9532d !important;
}

.headerCol-3 {
  float: right;
}

.productsNextBtnPosition {
  margin-top: 150px;
}


/*Our Team Styles */
@media (max-width: 768px) {
  .bg-Row1 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background: url("../backgrounds/OurTeamBg.jpeg");
  }

  .divOurTeam {
    border: 9px solid rgb(75, 75, 214);
    width: 80%;
    font-size: small;
    background-color: rgba(179, 181, 183, 0.549);
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .btnOurTeam {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .MeetOurTeam {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .imgPerson {
    
    margin: auto;
  }

  .bg-Row2 {
    background-position: left top;
    background-repeat: no-repeat !important;
    background-size: 100% 40% !important;
    background: url("../backgrounds/backHeader.jpg");
  }
}

@media (min-width: 768px) {

  .bg-Row1 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    background: url("../backgrounds/OurTeamBg.jpeg");
  }

  .divOurTeam {
    border: 9px solid rgb(75, 75, 214);
    width: 50%;
    height: 400px;
    background-color: rgba(179, 181, 183, 0.549);
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pTagOurTeam {
    margin-left: 30px;
    margin-right: 30px;
  }

  .btnOurTeam {
    display: block;
    margin-left: auto;
    margin-right: auto;

  }

  .bg-Row2 {
    background-position: left top;
    background-repeat: no-repeat !important;
    background-size: 100% 50% !important;
    background: url("../backgrounds/backHeader.jpg");
  }

  .MeetOurTeam {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .imgPerson {
   
    margin: auto;
  }
}

/*Aboiut Us Styles */

@media (max-width: 768px) {
  .paddingAboutUsPrior{
    padding-right:5px !important ;
  }
  .imgAboutUs {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .row1 {
    background-position: center top;
    background-position-x: center !important;
    background-repeat: no-repeat !important;
    background-size: 80% 30% !important;
    background: url('../backgrounds/teamImages.jpg');

  }

  .padding2 {
    padding-left: 10px;
  }

  .marginCart2 {
    margin-top: 40px;
  }

  .marginCart1 {
    margin-top: 50px;
  }

  .row2 {
    height: 150px;
  }

}

@media (min-width: 768px) {
  .imgAboutUs {
    margin-top: 40px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .row1 {
    background-position: center top;
    background-position-x: center !important;
    background-repeat: no-repeat !important;
    background-size: 70% 70% !important;
    background: url('../backgrounds/teamImages.jpg');

  }

  .padding2 {
    padding-left: 20px;
  }

  .marginCart1 {
    margin-top: 50px;
  }

  .row2 {
    height: 350px;
  }
}

/*Footer Styles */

@media (max-width: 768px) {
  .control {
    display: none;
  }

  .fontsize {
    font-size: small !important;
  }

  .h1Tags {
    font-size: larger !important;
    white-space: nowrap;
  }

  .logosSocial {
    width: 22px !important;
    height: 22px !important;
  }
}


.footer {
  background: #282727;
}

.btnFooter {
  margin: 5px;
}

.rowFooter {
  margin-top: 10px;
}

.aboutUsTextFooter {

  margin-left: auto !important;
  margin-right: auto !important;
}

.listFooter {
  list-style: none;
}

.liFooter {
  text-decoration: none;
}

.folowUsFooter {
  margin-left: 50px;
}

/* Animation*/
.cardAnim {
  transition: transform 0.3s ease;
}

.cardAnim :hover {
  transform: scale(0.98);
}


.textAnim:hover {
  color: rgb(215, 116, 17) !important;
  font-size: 105%;
}

.textAnimBlue:hover {
  color: rgb(54, 141, 248) !important;
  font-size: 110%;
}

.socialLogoAnim:hover {
  width: 40px;
  height: 40px;
}

.cardAnim2:hover {
  border: 3px rgb(16, 86, 199) solid;
}

.logoAnim:hover {
  transform: scale(1.10);
}



/*Gadget Style*/
.ulListNavGad {
  list-style-type: none;
}

.headerTextGad {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}


.NavBtnGad {
  padding: 0px;
  margin: auto;
  float: right;
}



/*About Us Pages */
.bgAboutUsPageCol{
  background-color: #5b190b;
}

/*Education pages*/
.eduRow2Bg{
  background: #bc2d56;
}
.margin30{
  margin-top: 30px;
}
.marginBottom30{
  margin-bottom: 30px;
}
.eduFontColor{
  color: #c22853;
}

/*navigation search button*/
.navSearch{
  display: none;
}

